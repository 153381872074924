import React, { FormEvent, useContext, useEffect, useState } from 'react';
// import SearchApi from '../../api/SearchApi';
import Api from '../../api/Api';
import SecondApi from '../../api/SecondApi';
import { Languagedata } from 'src/context/languagecontext';
import languageD from 'src/data/mainpage/language';

const Searchbar = () => {
    const [searchValue, setSearchValue] = useState<string>('kathmandu');
    const { language } = useContext(Languagedata);

    const handleSubmit = (event: FormEvent<HTMLFormElement>) => {
        event.preventDefault();
    };

    const useDebouncedValue = (inputValue: string, delay: number) => {
        const [debouncedValue, setDebouncedValue] = useState(inputValue);

        useEffect(() => {
            const handler = setTimeout(() => {
                setDebouncedValue(inputValue);
            }, delay);

            return () => {
                clearTimeout(handler);
            };
        }, [inputValue, delay]);

        return debouncedValue;
    };

    const debouncedSearchTerm = useDebouncedValue(searchValue, 500);


    return (
        <>
            <h1 className=" text-center lg:text-6xl  md:text-5xl text-4xl tracking-wide m-20  p-5 drop-shadow-2xl ">

                {languageD.title[language]}

            </h1>

            <div className='text-center max-w-xl mx-auto mt-20'>
                <label htmlFor="">{languageD.search[language]}:</label>
                <form onSubmit={handleSubmit} className='w-full border border-black p-1 m-2 text-black bg-white'>
                    <input
                        type="text"
                        value={searchValue}
                        onChange={(event) => setSearchValue(event.target.value)}
                        placeholder='Search for places'
                        className='w-[90%] bg-transparent outline-none p-1 '
                        required
                    />
                    <button type="submit"><i className="fa-solid  fa-magnifying-glass cursor-pointer"></i></button>
                </form>
            </div>
            <div>

                <div className='max-w-2xl mx-auto pt-5 flex justify-center flex-col items-center gap-7 text-center'>

                    <Api destination={debouncedSearchTerm} />
                </div>
                <div className='max-w-4xl mx-auto pt-5'>

                    <SecondApi destination={debouncedSearchTerm} />
                </div>
            </div >
        </>
    );
};

export default Searchbar;
