import React, { useEffect, useState } from "react";
import Weatherimage from "../components/section/Showimage/Weatherimage";
import Apicall from "../components/section/Apicall/Apicall";
import SecondApi from "./SecondApi";

interface Destination {
  destination: string;
}
interface WeatherData {
  name: string;
  main: {
    temp: number;
    temp_max: number;
    temp_min: number;
  };
  weather: {
    id: number;
    main: string;
    description: string;
    icon: string;
  }[];
  coord: {
    lat: number;
    lon: number;
  };
}

const Api = (props: Destination) => {
  const [weatherData, setWeatherData] = useState<WeatherData | null>(null);
  const [loading, setLoading] = useState<boolean>(true);
  const [error, setError] = useState<boolean>(false);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await fetch(
          `https://api.openweathermap.org/data/2.5/weather?q=${props.destination}&appid=2ed8c7c410ff6affeb74021552efda86`
        );
        if (!response.ok) {
          throw new Error("Invalid destination");
        }
        const data: WeatherData = await response.json();
        setWeatherData(data);
        setError(false);
      } catch (error) {
        setError(true);

      }
      finally {
        setLoading(false);
      }
    };

    fetchData();
  }, [props.destination]);

  return (
    <>
      {loading && <div>Loading...</div>}
      {!error && weatherData && (
        <Apicall
          main={weatherData?.weather?.[0]?.main}
          description={weatherData?.weather?.[0]?.description}
          temperature={(weatherData?.main?.temp - 273).toFixed(2)}
          name={weatherData?.name}
        />
      )}
      {error && (
        <div className="text-red-800">Country not found </div>
      )}
    </>
  );
};

export default Api;
