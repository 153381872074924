import React from 'react';
import sunny from "../../../assets/image/sunny.png"
import clouds from "../../../assets/image/clouds.png"
import drizzle from "../../../assets/image/drizzle.png"
import humidity from "../../../assets/image/humidity.png"
import rain from "../../../assets/image/rain.png"
import snow from "../../../assets/image/snow.png"
import strom from "../../../assets/image/strom.png"
import wind from "../../../assets/image/wind.png"
import mist from "../../../assets/image/mist.png"

interface Des {
    des: string;
}

const Weatherimage = (props: Des) => {

    let weatherImage;


    switch (props.des) {
        case ("clear sky"):
            return (
                weatherImage = <img src={sunny} alt="" width="130" className="mx-auto" />
            )
        case ("few clouds"):
            return (
                weatherImage = <img src={clouds} alt="" width="130" className="mx-auto" />

            )
        case ("scattered clouds"):
            return (
                weatherImage = <img src={humidity} alt="" width="130" className="mx-auto" />

            )
        case ("broken clouds"):
            return (
                weatherImage = <img src={wind} alt="" width="130" className="mx-auto" />

            )
        case ("shower rain"):
            return (
                weatherImage = <img src={drizzle} alt="" width="130" className="mx-auto" />

            )
        case ("rain"):
            return (
                weatherImage = <img src={rain} alt="" width="130" className="mx-auto" />

            )
        case ("thunderstrom"):
            return (
                weatherImage = <img src={strom} alt="" width="130" className="mx-auto" />

            )
        case ("snow"):
            return (
                weatherImage = <img src={snow} alt="" width="130" className="mx-auto" />

            )
        case ("mist"):
            return (
                weatherImage = <img src={mist} alt="" width="130" className="mx-auto" />

            )
        case ("modarate rain"):
            return (
                weatherImage = <img src={rain} alt="" width="130" className="mx-auto" />

            )
        default:
            weatherImage = <img src={rain} alt="" width="130" className="mx-auto" />


    }

    return (
        <div className='flex justify-center text-center align-center'>
            {weatherImage}

        </div>

    );
};

export default Weatherimage;