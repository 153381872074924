import React, { useEffect, useState } from "react";
import SecondApicall from "../components/section/Apicall/SecondApicall";


interface Destination {
  destination: string;

}

interface WeatherData {
  list: {
    main: {
      temp: number;

    };
    weather: {
      id: number;
      main: string;
      description: string;
    }[];
  }[];
}





const SecondApi = (props: Destination) => {
  const [weatherData, setWeatherData] = useState<WeatherData | null>(null);
  const [loading, setLoading] = useState<boolean>(true);
  const [error, seterror] = useState<boolean>(false)


  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await fetch(
          `https://api.openweathermap.org/data/2.5/forecast?q=${props.destination}&appid=7b60292854ffb6e83eab2a60d8bc81b7`
        );
        if (!response.ok) {
          throw new Error("Invalid destination");
        }
        const data: WeatherData = await response.json();
        setWeatherData(data);
        seterror(false);
      } catch (error) {
        console.log("error occurred:", error);
        seterror(true);
      } finally {
        setLoading(false);
      }
    };

    fetchData();
  }, [props.destination]);

  return (
    <>
    
      {!loading && !error && weatherData && (
        <SecondApicall
          temperature1={(weatherData.list?.[0]?.main?.temp - 273.15).toFixed(2)}
          temperature2={(weatherData.list?.[1]?.main?.temp - 273.15).toFixed(2)}
          temperature3={(weatherData.list?.[2]?.main?.temp - 273.15).toFixed(2)}
          temperature4={(weatherData.list?.[3]?.main?.temp - 273.15).toFixed(2)}
          description1={weatherData?.list?.[0]?.weather[0].description}
          description2={weatherData?.list?.[1]?.weather[0].description}
          description3={weatherData?.list?.[2]?.weather[0].description}
          description4={weatherData?.list?.[3]?.weather[0].description}

        />
      )}


      {!loading && error && (
        <div></div>
      )}
    </>
  );
};

export default SecondApi;
