import { LabelType } from "src/types/const.type";

const languageD:LabelType = {
    title: {
        "en": " Good weather, good vibes.",
        "ne": "राम्रो मौसम, राम्रो अनुभूति"
    },
    footer:{
        "en":"Designed and Developed by aaryae.",
        "ne":"आर्य  डंगोल द्वारा डिजाइन र विकास'"
    },
    
    search:{
        "en":"search for places",
        "ne":"ठाउँहरू खोज्नुहोस्"
    }

};

export default languageD;


// <span className='text-[#cb850f]'>,</span>

