import React, { useContext, useEffect } from "react";
import "../../App.css"
import { Contextdata} from "../../context/contextapi";

const Navbar = () => {

  const { contextvalue } = useContext(Contextdata);

  useEffect(() => {
    if (contextvalue.mode) {
      document.body.classList.remove('herohoni');
      document.body.classList.add('herohoni-alternate');
    } else {
      document.body.classList.add('herohoni');
      document.body.classList.remove('herohoni-alternate');
    }
  }, [contextvalue]);



  return (
    <div className=" text-4xl p-3 m-3 w-fit border border-[#fea713] border-0 border-b-2 cursor-pointer ">
      <span>
        <span className={`text-7xl font-serif ${contextvalue.mode ? 'text-[#cb850f]' : 'text-black'}`}>M</span>
        <span>ousam</span>
      </span>
    </div>
  );
};

export default Navbar;
