import React from 'react';
import "./App.css";
import Toggle from "./assets/toggleswitch/Toggle";
import Footer from "./components/Footer/Footer";
import Searchbar from "./components/Searchbar/Searchbar";
import Navbar from "./components/logo/logo";
import { ContextProvider } from './context/contextapi';
import { Languagecontextprovider } from './context/languagecontext';
import Lswitch from "./assets/languageswitch/Lswitch"


function App() {


  return (

    <Languagecontextprovider>
      <ContextProvider >
        <Navbar />
        <Toggle />
        <Lswitch />
        <Searchbar />
        <Footer />
      </ContextProvider>
    </Languagecontextprovider>


  );
}

export default App;
