import React, { useContext } from 'react';
import nepal from "../../assets/image/nepalFlag.svg";
import english from "../../assets/image/usFlag.svg";
import { Languagedata } from 'src/context/languagecontext';
import { LanguageEnum } from 'src/types/const.type';


const Lswitch = () => {
    const { language, setlanguage } = useContext(Languagedata);
    const img = language === LanguageEnum.en ? english : nepal;

    const handleclick = () => {
        const newLanguage: LanguageEnum = language === LanguageEnum.en ? LanguageEnum.ne : LanguageEnum.en;
        setlanguage(newLanguage);
    }

    return (
        <div onClick={handleclick} className='absolute top-[27px] right-[100px]  text-center rounded-full bg-white overflow-hidden cursor-pointer'>
            <img src={img} alt="flag" style={{ height: '30px', width: '30px' }} />
        </div>
    );
}

export default Lswitch;
