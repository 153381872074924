import React, { useContext, useEffect } from 'react';
import './toggle.css';
import { Contextdata } from '../../context/contextapi';
import { Languagedata } from 'src/context/languagecontext';
import languageD from 'src/data/mainpage/language';

const Toggle = () => {
    const { contextvalue, setcontextvalue } = useContext(Contextdata);
    const { language } = useContext(Languagedata)

    useEffect(() => {
        const storedValue = localStorage.getItem('contextvalue');
        if (storedValue !== null) {
            setcontextvalue(JSON.parse(storedValue));
        }
    }, [setcontextvalue]);

    const handleclick = () => {
        setcontextvalue((prevState) => ({
            ...prevState,
            mode: !prevState.mode
        }));

        localStorage.setItem('contextvalue', JSON.stringify({
            ...contextvalue,
            mode: !contextvalue.mode
        }));
    };



    return (


        <>

            <div className='absolute top-0 right-0 p-3 m-3 text-center '>
                <label className="switch">
                    <input type="checkbox" onClick={handleclick} />
                    <span className="slider round"></span>
                </label>

            </div>

        </>
    );
};

export default Toggle;
