import React from 'react'


interface WeatherData {
    temperature1: string;
    temperature2: string;
    temperature3: string;
    temperature4: string;
    description1: string;
    description2: string;
    description3: string;
    description4: string;
}

const SecondApicall = (props: WeatherData) => {
    let date: Date = new Date();
    // const nepalidate = new BikramSambat(date).toBS()
  
    const date2 = new Date(date)
    const date3 = new Date(date);
    const date4 = new Date(date);
  
    date2.setDate(date.getDate() + 1);
    date3.setDate(date.getDate() + 2);
    date4.setDate(date.getDate() + 3);
    return (
        <div className="flex flex-wrap justify-around m-3 p-3 bg-transparent ">
            <div className="flex w-40 text-center flex-col gap-5 border border-black m-5 p-3 shadow">
                <p>Tomorrow</p>
                <h1 className="text-3xl">
                    {props.temperature1}
                    ° C
                </h1>
                <p>{props.description1}</p>
            </div>
            <div className="flex w-40 text-center  flex-col gap-5 border border-black m-5 p-3 shadow">
                <p>{date2.toLocaleDateString()}</p>
                <h1 className="text-3xl">
                    {props.temperature2}
                    ° C
                </h1>
                <p>{props.description2}</p>
            </div>
            <div className="flex w-40 text-center  flex-col gap-5 border border-black m-5 p-3 shadow">
                <p>{date3.toLocaleDateString()}</p>
                <h1 className="text-3xl">
                    {" "}
                    {props.temperature3}
                    ° C
                </h1>
                <p>{props.description3}</p>
            </div>
            <div className="flex w-40 text-center  flex-col gap-5 border border-black m-5 p-3 shadow">
                <p>{date4.toLocaleDateString()}</p>
                <h1 className="text-3xl">
                    {" "}
                    {props.temperature4}
                    ° C
                </h1>
                <p>{props.description4}</p>
            </div>
        </div>
    )
}


export default SecondApicall