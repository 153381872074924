import React, { createContext, useState } from "react";



//themecontext

interface Themetype {
  mode: boolean;
}
interface ContextValueType {
  contextvalue: Themetype;
  setcontextvalue: React.Dispatch<React.SetStateAction<Themetype>>;
}

const Contextdata = createContext<ContextValueType>({
  contextvalue: { mode: false },
  setcontextvalue: () => { },
});

interface CT {
  children: React.ReactNode;
}

const ContextProvider = ({ children }: CT) => {
  const [contextvalue, setcontextvalue] = useState<Themetype>({
    mode: false,
  });

  return (
    <Contextdata.Provider value={{ contextvalue, setcontextvalue }}>
      {children}
    </Contextdata.Provider>
  );
};







export { Contextdata, ContextProvider };

