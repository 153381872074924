import React from 'react'
import Weatherimage from '../Showimage/Weatherimage';

interface WeatherData {
    main: string;
    description: string;
    temperature: string;
    name: string;
}

const Apicall = (props: WeatherData) => {
    return (
        <>
            <div className="font-bold tracking-wider text-xl text-center m-3">
                {props.main}-
                {props.description}
            </div>
            <Weatherimage des={props.description} />
            <p className=" w-fit p-8 text-6xl">
                {props.temperature}° C
                <br />
                <span className="text-xl">in  {props.name} </span>

            </p>



        </>
    )
}

export default Apicall




