import React, { useContext } from "react";
import { Languagedata } from "src/context/languagecontext";
import languageD from "src/data/mainpage/language";

const Footer = () => {
  const { language } = useContext(Languagedata);
  return (

    <div className="flex justify-center items-center kxa text-white  text-center">
      {languageD.footer[language]}
    </div>
  );
};

export default Footer;
