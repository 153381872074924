import { createContext, useState } from "react";
import { LanguageEnum } from "src/types/const.type";



interface LanguageStatetype {
    language: LanguageEnum,
    setlanguage: React.Dispatch<React.SetStateAction<LanguageEnum>>;
}

const Languagedata = createContext<LanguageStatetype>({
    language: LanguageEnum.ne,
    setlanguage: () => { }
})

interface Childrentype {
    children: React.ReactNode
}

const Languagecontextprovider = ({ children }: Childrentype) => {
    const [language, setlanguage] = useState<LanguageEnum>(LanguageEnum.ne)
    return (
        <Languagedata.Provider value={{ language, setlanguage }}>
            {children}
        </Languagedata.Provider >
    )
}

export { Languagedata, Languagecontextprovider }


